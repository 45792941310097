body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.canvasjs-chart-credit {
	display: none !important;
}

.table.table-sm td {
	padding: 1.375rem 8px !important;
	height: 100%;
	text-align: center;
}

.pagination .page-item.active .page-link {
	border-radius: 10px !important;
	color: #f49c10 !important;
	border: 1px solid #1d1d1d !important;
	background-color: transparent !important;
}

.page-link {
	padding: 0 12px !important;
	height: 37.02px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}

.table > tbody {
	vertical-align: middle !important;
}

.table.table thead th {
	font-size: 20px;
	line-height: 22px;
}

.pagination,
.dataTables_info,
.dataTables_paginate {
	display: none !important;
}
